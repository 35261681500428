export const deleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        value: "(刪除門鎖時，將一起刪除透過本系統所設定的密碼)",
        name: "deleteFormNote",
        css:"deleteFormNote"
    },
    {
        type: "text",
        label: "門鎖配對房間：",
        labelPosition: "left",
        value: "",
        name: "deleteFormName",
        css: "deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA112",
    },
];