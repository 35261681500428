export const checkIn_roomGridColumn = [
    { id: "isChecked", width:60, header: [{ text: "入住" }],htmlEnable: true,
        tooltip: false,
        // template: function (text, row, col) {
        //     return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'></div>";
        // },type: "boolean"
    },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { id: "check_in_out_date", width:310, header: [{ text: "入住/退房日期" }] },
    { id: "room_type", width:120,  header: [{ text: "房型" }] },
    { id: "room_name", width:120,  header: [{ text: "房間名稱" }] },
    { id: "quantity", width:60, header: [{ text: "數量" }] },
    { id: "price", width:80, header: [{ text: "價格" }] },
    { id: "totalPrice", width:80, header: [{ text: "小計" }], format: "#,#.##" },
    { id: "sale_source", width: 150, header: [{ text: "銷售來源" }] },
    { id: "seller", width: 150, header: [{ text: "銷售人員" }] },
    { id: "created_by", width:120, header: [{ text: "建立人員" }] },
    { id: "created_at", width:150, header: [{ text: "建立時間" }] },
    { id: "updated_by", width:120, header: [{ text: "更新人員" }] },
    { id: "updated_at", width:150, header: [{ text: "更新時間" }] },
];