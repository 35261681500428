export const toolbar1Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    // {id: "sync", type: "button", circle: true, value: "同步狀態", size: "small", icon: "fa-solid fa-rotate"},
    {
        id: "keyword",
        type: "input",
        value: "",
        placeholder: "",
        label: "房號關鍵字："
    },
    {
        type: "title",
        value: "門鎖狀態："
    },
    {
        id: "status",
        type: "selectButton",
        value: "全部",
        items: [
            {
                value: "全部",
            },
            {
                value: "離線",
            },
            {
                value: "上線",
            },
        ]
    },
    {
        type: "title",
        value: "電量："
    },
    {
        id: "battery",
        type: "selectButton",
        value: "全部",
        items: [
            {
                value: "全部",
            },
            {
                value: "低於40%",
            },
            {
                value: "大於或等於40%",
            },
        ]
    },
    {id:"search", type: "button", value: "搜尋", size: "small", icon: "fa-solid fa-magnifying-glass", circle:true},
];