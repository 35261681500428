let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

export const changeRoom_restRoomFormConfig = [
    { type: "input", name: "AG001", hidden: true, value: "0" },
    { type: "input", name: "AG002", hidden: true, value: "0" },
    { type: "input", name: "AG003", hidden: true, value: "0" },
    { type: "input", name: "AG131", hidden: true, value: "0" },
    { type: "input", name: "AG132", hidden: true, value: "0" },
    { type: "input", name: "AG133", hidden: true, value: "0" },
    { type: "input", name: "AG134", hidden: true, value: "0" },
    { type: "input", name: "AA002", hidden: true, value: "0" },
    { type: "input", name: "AA003", hidden: true, value: "0" },
    { type: "input", name: "AA004", hidden: true, value: "0" },
    { type: "input", name: "roomTypeName", hidden: true, value: "" },
    {
        cols:[
            {
                type: "datepicker",
                label: "*入住日期時間：",
                timePicker: true,
                timeFormat: 24,
                width: "33.2%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                name: "AG119",
            },
            {
                width: "33.2%",
                padding:"0px 0px 0px 10px",
                cols:[
                    {
                        type: "radioGroup",
                        label: " ",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_1",
                        css:"newOrder_restRoomGridForm_Radio noLabel",
                    },
                    {
                        type: "select",
                        label: " ",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "30",content: "30"},
                            {value: "60",content: "60"},
                            {value: "90",content: "90"},
                            {value: "120",content: "120"},
                            {value: "150",content: "150"},
                            {value: "180",content: "180"},
                            {value: "210",content: "210"},
                            {value: "240",content: "240"},
                            {value: "270",content: "270"},
                            {value: "300",content: "300"},
                            {value: "330",content: "330"},
                            {value: "360",content: "360"},
                            {value: "390",content: "390"},
                            {value: "420",content: "420"},
                            {value: "450",content: "450"},
                            {value: "480",content: "480"},
                        ],
                        name: "AA104_1",
                        css: "noLabel"
                    },
                    {
                        type: "text",
                        label: " ",
                        value:"分鐘",
                        css:"rightMargin noLabel",
                        name: "AA104_text1",
                    },
                ]
            },
            {
                width: "33.2%",
                cols:[
                    {
                        type: "radioGroup",
                        label: " ",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_2",
                        css:"newOrder_restRoomGridForm_Radio  noLabel",
                    },
                    {
                        type: "select",
                        label: " ",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "1",content: "1"},
                            {value: "2",content: "2"},
                            {value: "3",content: "3"},
                            {value: "4",content: "4"},
                            {value: "5",content: "5"},
                            {value: "6",content: "6"},
                            {value: "7",content: "7"},
                            {value: "8",content: "8"},
                            {value: "9",content: "9"},
                            {value: "10",content: "10"},
                            {value: "11",content: "11"},
                            {value: "12",content: "12"},
                        ],
                        name: "AA104_2",
                        css: "noLabel"
                    },
                    {
                        type: "text",
                        label: " ",
                        value:"小時",
                        css:"rightMargin  noLabel",
                        name: "AA104_text2",
                    },
                ]
            },
        ]
    },
    {
        cols:[
            {
                type: "combo",
                multiselection: false,
                label: "*房型：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                readOnly:true,
                disabled:true,
                name: "AG004",
            },
            {
                type: "combo",
                multiselection: false,
                label: "*房間名稱：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                readOnly:true,
                disabled:true,
                name: "AG005",
            },
        ],
    },
    {
        cols: [
            {
                type: "input",
                label: "*金額：",
                labelPosition: "top",
                width:"49.7%",
                css:"rightMargin",
                inputType:"number",
                name: "AG127"
            },
        ],
    },
    {
        type: "checkbox",
        label: "",
        labelWidth: "0px",
        labelPosition: "left",
        text: "故障通報(房間狀態為待入住或已入住才可使用)",
        // checked: true,
        name: "needReportFault",
        id: "needReportFault",
    },
    {
        css: "row1",
        rows:[
            {
                cols:[
                    {
                        type: "input",
                        label: "房間名稱：",
                        css:"rightMargin",
                        disabled:true,
                        placeholder: "",
                        readOnly: true,
                        width: "49.7%",
                        name: "roomName",
                    },
                    {
                        type: "datepicker",
                        label: "*通報日期：",
                        dateFormat: "%Y-%m-%d",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA103",
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "datepicker",
                        label: "*鎖定開始日期：",
                        dateFormat: "%Y-%m-%d 00:00:00",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA104",
                    },
                    {
                        type: "datepicker",
                        label: "*鎖定結束日期：",
                        dateFormat: "%Y-%m-%d 23:59:59",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA105",
                    },
                ],
            },
            {
                type: "select",
                label: "*故障原因：",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                name: "AA006",
            },
            {
                type: "textarea",
                label: "備註：",
                value: "",
                height: 100,
                name: "AA106",
            },
        ],

    },
];