import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDAlert from "../../../components/MDAlert";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
    Tabbar as DHXTabbar,
    Grid as DHXGrid,
    Toolbar as DHXToolbar,
    Layout as DHXLayout,
    Form as DHXForm,
    Window as DHXWindow,
    DataView as DHXDataview,
    message as DHXMessage,
} from "dhx-suite";
import {doorLockSetGridColumn} from "./componentsConfig/doorLockSetGridColumn";
import {toolbar2Config} from "./componentsConfig/toolbar2Config";
import {WindowFootAccept,WindowFootClear,WindowFootClose} from "./componentsConfig/windowConfig";
import {doorLockSetFormConfig} from "./componentsConfig/doorLockSetFormConfig";
import {hotelLockSetFormConfig} from "./componentsConfig/hotelLockSetFormConfig";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import {doorLockEventLogGridColumn} from "./componentsConfig/doorLockEventLogGridColumn";
import {doorLockGridColumn} from "./componentsConfig/doorLockGridColumn";
import {passwordGridColumn} from "./componentsConfig/passwordGridColumn";
import './style.css';
import Progress from '../../../assets/images/icons8-iphone-spinner.gif';
import axios from "axios";
import api_url from "../../../context/UrlContext";
import Image_error from "../../../assets/images/error.png";
import {passwordGridFormConfig} from "./componentsConfig/passwordGridFormConfig";
import {roomGridColumn} from "./componentsConfig/roomGridColumn";

const PasswordBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXPasswordBasicSettingLayout = useRef(null);

    let selectLockIdArray = [];
    let passwordGridSpanTmp = "";
    let isNeedFirstLoadRoomGrid = false;
    let passwordGridId = "0";

    useEffect(() => {

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>請耐心等候</span></div>",
            css: "dhxProgressWindow1"
        });

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:37},headers)
                        .then((response2)=>{
                            if(response2.status === 200){
                                if(response2.data){
                                    let p = response2.data.split(',');

                                    (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                    (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                    (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                    (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                    setIsloading(false);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        const dhxPasswordBasicSettingLayout = new DHXLayout(DHXPasswordBasicSettingLayout.current,{
            type: "line",
            rows: [
                {
                    id: "passwordBasicSettingToolbar",
                    height: "50px",
                },
                {id:"passwordGrid"}
            ]
        });

        const dhxPasswordWindow1 = new DHXWindow({
            title: "新增密碼視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 460,
            width:650,
            footer: true,
            css: "dhxPasswordWindow1"
        });

        dhxPasswordWindow1.footer.data.add({type:"spacer"});
        dhxPasswordWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxPasswordWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxPasswordWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxPasswordWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    passwordGridForm.clear();
                    passwordGridForm.setValue({"AA001": "0","AA002":hotel,"AA003":"0","AA004":"0"});
                    passwordGridForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(passwordGridForm.getItem("AD101").getValue() === ""){
                        DHXMessage({
                            text: "請輸入使用者",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(passwordGridForm.getItem("AD103").getValue() === "" || passwordGridForm.getItem("AD104").getValue() === ""){
                        DHXMessage({
                            text: "請輸入密碼有效時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(selectLockIdArray.length === 0){
                        DHXMessage({
                            text: "請選擇要設定密碼的房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(selectLockIdArray.length > 10){
                        DHXMessage({
                            text: "房間最多選取10間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/accAndDev/checkLockStatus", {
                            itemId1: selectLockIdArray
                        },headers
                    ).then((response) =>{

                        if(response.status === 200){
                            if(response.data){
                                if(response.data.result === 1){
                                    axios.post(apiServer+"/accAndDev/updatePassword", {
                                            itemId1: passwordGridForm.getValue(), itemId2: selectLockIdArray
                                        },headers
                                    ).then((response2) =>{

                                        if(response2.status === 200){
                                            if(response2.data){

                                                DHXMessage({
                                                    text: response2.data.message,
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--success",
                                                    expire:5000
                                                });

                                                if(passwordGridSpanTmp != ""){
                                                    passwordGridSpanTmp.map((item)=>{
                                                        passwordGrid.removeSpan(item.row,item.column);
                                                    })
                                                }

                                                axios.post(apiServer+"/accAndDev/loadPasswordGrid", {
                                                        itemId1: hotel
                                                    },headers
                                                ).then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            passwordGrid.data.parse(response3.data.password_json);

                                                            passwordGridSpanTmp = response3.data.spanArray;

                                                            setTimeout(()=>{
                                                                if(response3.data.spanArray.length != 0){
                                                                    response3.data.spanArray.map((item)=>{
                                                                        passwordGrid.addSpan(item);
                                                                        dhxPasswordWindow1.hide();
                                                                        dhxProgressWindow1.hide();
                                                                    })
                                                                }else{
                                                                    dhxPasswordWindow1.hide();
                                                                    dhxProgressWindow1.hide();
                                                                }
                                                            },1000);
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }

                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                            }else{
                                                console.log(1);
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            console.log(2);
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            console.log(2);
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else if(response.data.result === 9){
                                    console.log(4);
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: response.data.message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                console.log(5);
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPasswordWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const passwordGridForm = new DHXForm(null,{
            rows: passwordGridFormConfig
        });

        const roomGrid = new DHXGrid(null,{
            columns: roomGridColumn,
            autoWidth: true,
            resizable: true,
            height: 350,
            sortable: false,
            eventHandlers:{
                onclick:{
                    "cell__template": function(e, data) {

                        if(data.col.id == "isChecked"){

                            if(e.target.checked != undefined){

                                if(e.target.checked){
                                    let findId = selectLockIdArray.find((item, index, array)=>{
                                        return item == data.row.lockId
                                    });

                                    if(findId == undefined){
                                        selectLockIdArray.push(data.row.lockId);
                                    }
                                }else{
                                    selectLockIdArray = selectLockIdArray.filter((item, index, array)=>{
                                        return item != data.row.lockId;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });

        const dhxPasswordGridFormLayout = new DHXLayout(null,{
            // type: "line",
            rows: [
                {
                    cols: [
                        {id:"passwordGridForm"},
                        {
                            id: "roomGrid", width: "235px",header: "*房間："
                        },
                        // {
                        //     id: "roomGrid", width: "235px",header: "*房間："
                        // },
                    ]
                },
                {id:"roomGridTip",html: "<span style='color: red;'>門鎖同步需要時間，為避免同步太久被瀏覽器判定超時導致同步失敗，房間最多選取10間</span>",height: "30px"},
            ],
        });

        dhxPasswordGridFormLayout.getCell("roomGrid").attach(roomGrid);
        dhxPasswordGridFormLayout.getCell("passwordGridForm").attach(passwordGridForm);
        dhxPasswordWindow1.attach(dhxPasswordGridFormLayout);

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    passwordGridId = "0";
                    dhxPasswordWindow1.show();
                    roomGrid.data.removeAll();
                    selectLockIdArray = [];
                    passwordGridForm.clear();
                    passwordGridForm.setValue({"AD001":"0","AD002":hotel});

                    axios.post(apiServer+"/accAndDev/loadCanSetRoomLockGrid", {
                        itemId1: hotel
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    roomGrid.data.removeAll();
                                    roomGrid.data.parse(response.data);
                                    // selectLockIdArray = [];
                                    dhxProgressWindow1.hide();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    isNeedFirstLoadRoomGrid = true;
                    break;

                case "reload":
                    passwordGrid.data.removeAll();
                    passwordGrid.data.removeAll();
                    dhxProgressWindow1.show();

                    if(passwordGridSpanTmp != ""){
                        passwordGridSpanTmp.map((item)=>{
                            passwordGrid.removeSpan(item.row,item.column);
                        })
                    }

                    axios.post(apiServer+"/accAndDev/loadPasswordGrid", {
                            itemId1: hotel
                        },headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                passwordGrid.data.parse(response.data.password_json);

                                passwordGridSpanTmp = response.data.spanArray;

                                setTimeout(()=>{
                                    if(response.data.spanArray.length != 0){
                                        response.data.spanArray.map((item)=>{
                                            passwordGrid.addSpan(item);
                                            dhxProgressWindow1.hide();
                                        })
                                    }else{
                                        dhxProgressWindow1.hide();
                                    }
                                },1000);
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const passwordGrid = new DHXGrid(null,{
            columns: passwordGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
            onclick: {
                "passwordGrid_edit": function (e, data) {
                    isNeedFirstLoadRoomGrid = false;
                    if(canEdit){
                        roomGrid.data.removeAll();
                        passwordGridForm.clear();
                        dhxPasswordWindow1.header.data.update("title", {value: "編輯密碼視窗"});
                        dhxPasswordWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                        axios.post(apiServer+"/accAndDev/loadPasswordGridFormData", {
                            itemId1: data.row.id2
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    passwordGridForm.setValue(response.data.password_json[0]);
                                    passwordGridId = response.data.password_json[0].AD001;
                                    roomGrid.data.parse(response.data.room_json);
                                    selectLockIdArray = response.data.roomArray;
                                    isNeedFirstLoadRoomGrid = true;
                                    dhxPasswordWindow1.show();
                                    dhxProgressWindow1.hide();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                },
                "passwordGrid_delete": function (e, data) {
                    if(canDelete){
                        passwordDeleteForm.clear();
                        passwordDeleteForm.setValue({"AD001":data.row.id2,"AD002":hotel});

                        axios.post(apiServer+"/accAndDev/loadPasswordGridFormData", {
                            itemId1: data.row.id2
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    passwordDeleteForm.setValue(response.data.password_json[0]);
                                    // passwordDeleteForm.setValue({"roomArray":response.data.roomArray});
                                    // passwordGridId = response.data.password_json[0].AD001;
                                    // roomGrid.data.parse(response.data.room_json);
                                    selectLockIdArray = response.data.roomArray;
                                    let roomNameList = "";
                                    response.data.room_json.map((item)=>{
                                        roomNameList += item.roomName + "、";
                                    })

                                    roomNameList = roomNameList.substring(0,roomNameList.length - 1);
                                    passwordDeleteForm.getItem("roomNameList").setValue(roomNameList);

                                    dhxPasswordWindow2.show();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                console.log(error)
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有刪除的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }
            }
        }
        });

        const dhxPasswordWindow2 = new DHXWindow({
            title: "刪除密碼設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 520,
            footer: true,
            css: "dhxPasswordWindow2",
        });

        dhxPasswordWindow2.footer.data.add({type:"spacer"});
        dhxPasswordWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxPasswordWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxPasswordWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(passwordDeleteForm.getItem("AD106").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/accAndDev/checkLockStatus", {
                            itemId1: selectLockIdArray
                        },headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                if(response.data.result === 1){
                                    axios.post(apiServer+"/accAndDev/deletePassword",
                                        passwordDeleteForm.getValue(),headers
                                    ).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                DHXMessage({
                                                    text: "更新成功",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--success",
                                                    expire:5000
                                                });

                                                if(passwordGridSpanTmp != ""){
                                                    passwordGridSpanTmp.map((item)=>{
                                                        passwordGrid.removeSpan(item.row,item.column);
                                                    })
                                                }

                                                axios.post(apiServer+"/accAndDev/loadPasswordGrid", {
                                                        itemId1: hotel
                                                    },headers
                                                ).then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            passwordGrid.data.removeAll();
                                                            passwordGrid.data.parse(response3.data.password_json);

                                                            passwordGridSpanTmp = response3.data.spanArray;

                                                            setTimeout(()=>{
                                                                if(response3.data.spanArray.length != 0){
                                                                    response3.data.spanArray.map((item)=>{
                                                                        passwordGrid.addSpan(item);
                                                                        dhxPasswordWindow2.hide();
                                                                        dhxProgressWindow1.hide();
                                                                    })
                                                                }else{
                                                                    dhxPasswordWindow2.hide();
                                                                    dhxProgressWindow1.hide();
                                                                }
                                                            },1000)
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        })
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--danger",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else if(response.data.result === 9){
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: response.data.message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    dhxPasswordWindow2.hide();
                    passwordDeleteForm.clear();
                    passwordDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let passwordDeleteFormLayout = new DHXLayout(null,{
            rows: [
                {id:"roomGridTip",html: "<h1 class='deleteFormTitle' style='padding-top: 5px'>確定要刪除嗎?</h1>",height: "30px"},
                {id:"passwordDeleteForm"},
            ],
        });

        const passwordDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxPasswordWindow2.attach(passwordDeleteFormLayout);
        passwordDeleteFormLayout.getCell("passwordDeleteForm").attach(passwordDeleteForm);

        if(passwordGridSpanTmp != ""){
            passwordGridSpanTmp.map((item)=>{
                passwordGrid.removeSpan(item.row,item.column);
            })
        }

        axios.post(apiServer+"/accAndDev/loadPasswordGrid", {
                itemId1: hotel
            },headers
        ).then((response) =>{
            if(response.status === 200){
                if(response.data){
                    passwordGrid.data.removeAll();
                    passwordGrid.data.parse(response.data.password_json);

                    passwordGridSpanTmp = response.data.spanArray;

                    setTimeout(()=>{
                        if(response.data.spanArray.length != 0){
                            response.data.spanArray.map((item)=>{
                                passwordGrid.addSpan(item);
                                dhxProgressWindow1.hide();
                            })
                        }else{
                            dhxProgressWindow1.hide();
                        }
                    },1000)
                }else{
                    dhxProgressWindow1.hide();
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            }else{
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                })
            }
        })
            .catch((error) => {
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        // dhxPasswordBasicSettingLayout.getCell("doorLockGrid").attach(doorLockGrid);
        dhxPasswordBasicSettingLayout.getCell("passwordGrid").attach(passwordGrid);
        dhxPasswordBasicSettingLayout.getCell("passwordBasicSettingToolbar").attach(toolbar1);

        return () =>{
            dhxPasswordBasicSettingLayout && dhxPasswordBasicSettingLayout.destructor();
        }

    }, [canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <h4 className={"gridTitle"}>門鎖密碼設定</h4>
                            <MDBox py={1} px={1}>
                                <div ref={DHXPasswordBasicSettingLayout} style={{height:"800px"}}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default PasswordBasicSetting;