
// @mui icons
import Icon from "@mui/material/Icon";
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

// Images
import UserProfileSetting from "layouts/basicSetting/accountProfileSetting";
import GlobalVarSetting from "./layouts/basicSetting/globalVarSetting";
import ProductBasicSetting from "./layouts/product/productBasicSetting";
import OrderBasicSetting from "./layouts/order/orderBasicSetting";
import TodayOrderBasicSetting from "./layouts/order/todayOrderBasicSetting";
import CanCheckInOrderBasicSetting from "./layouts/order/canCheckInOrderBasicSetting";
import NoRoomOrderBasicSetting from "./layouts/order/noRoomOrderBasicSetting";
import UnpaidOrderBasicSetting from "./layouts/order/unpaidOrderBasicSetting";
import TodayRemindOrderBasicSetting from "./layouts/order/todayRemindOrderBasicSetting";
import FaultReportSetting from "./layouts/faultReport/faultReportSetting"
import PaymentMethodSetting from "./layouts/paymentMethod/paymentMethodSetting";
import ReserveBasicSetting from "./layouts/reserve/reserveBasicSetting";
import CounterHandOverSetting from "./layouts/handOverControl/counterHandOverSetting";
import HousekeepingHandOverSetting from "./layouts/handOverControl/housekeepingHandOverSetting";
import PublicWorksHandOverSetting from "./layouts/handOverControl/publicWorksHandOverSetting";
import HousekeepingReport from "./layouts/handOverControl/housekeepingReport";
import RepairReport from "./layouts/handOverControl/repairReport";

import RoomBasicSetting from "./layouts/product/roomBasicSetting";
import MainProductsBasicSetting from "./layouts/product/mainProductsSetting";

import AccessControlBasicSetting from "./layouts/accessControl/accessControlBasicSetting";
import EventsBasicSetting from "./layouts/accessControl/eventsBasicSetting";
import PasswordBasicSetting from "./layouts/accessControl/passwordBasicSetting";
import EntranceLockBasicSetting from "./layouts/accessControl/entranceLockBasicSetting";
import DeviceControlBasicSetting from "./layouts/deviceControl/deviceControlBasicSetting";

import TicketBasicSetting from "./layouts/ticket/ticketBasicSetting";
import SalesChannelBasicSetting from "./layouts/ticket/salesChannelBasicSetting";
import SmartBookingSetting from "./layouts/smartBooking/smartBookingSetting";

import OtherProductsBasicSetting from "./layouts/otherProduct/otherProductsBasicSetting";
import PricesBasicSetting from "./layouts/prices/pricesBasicSetting";

import Dashboard from "./layouts/dashboard";
import SalesReport from "./layouts/reportControl/salesReport";
import DailySalesReport from "./layouts/reportControl/dailySalesReport";
import ResourceSalesReport from "./layouts/reportControl/resourceSalesReport";
import ProductsReport from "./layouts/reportControl/productsReport";
import SellerReport from "./layouts/reportControl/sellerReport";
import SalesTargetReport from "./layouts/reportControl/salesTargetReport";
import InvoiceReport from "./layouts/reportControl/invoiceReport";
import QuesAndAnsSetting from "./layouts/quesAndAns/quesAndAnsSetting";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Login from "./layouts/login";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop,faTable,faHotel,faScroll,faTicket,faScrewdriverWrench,faSwatchbook,faUsers,faTableList } from '@fortawesome/free-solid-svg-icons';

const routes = [
    {
        type: "collapse",
        name: "即時狀態",
        key: "dashboard",
        icon: <FontAwesomeIcon icon={faTable} />,
        route: "/dashboard",
        component: <Dashboard />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "訂單管理",
        key: "orderControl",
        icon: <FontAwesomeIcon icon={faTableList} />,
        collapse: [
            {
                name: "全部訂單",
                key: "orderBasicSetting",
                route: "/systemMang/order/orderBasicSetting",
                component: <OrderBasicSetting/>
            },
            {
                name: "今日入住",
                key: "todayOrderBasicSetting",
                route: "/systemMang/order/todayOrderBasicSetting",
                component: <TodayOrderBasicSetting/>
            },
            {
                name: "未入住",
                key: "canCheckInOrderBasicSetting",
                route: "/systemMang/order/canCheckInOrderBasicSetting",
                component: <CanCheckInOrderBasicSetting/>
            },
            {
                name: "未分配房間",
                key: "noRoomOrderBasicSetting",
                route: "/systemMang/order/noRoomOrderBasicSetting",
                component: <NoRoomOrderBasicSetting/>
            },
            {
                name: "未付款",
                key: "unpaidOrderBasicSetting",
                route: "/systemMang/order/unpaidOrderBasicSetting",
                component: <UnpaidOrderBasicSetting/>
            },
            {
                name: "今日提醒",
                key: "todayRemindOrderBasicSetting",
                route: "/systemMang/order/todayRemindOrderBasicSetting",
                component: <TodayRemindOrderBasicSetting/>
            },
        ],
    },
    // {
    //     type: "collapse",
    //     name: "訂單處理",
    //     key: "ordBasicSet",
    //     icon: <FontAwesomeIcon icon={faTableList} />,
    //     route: "/systemMang/order/orderBasicSetting",
    //     component: <OrderBasicSetting/>,
    //     noCollapse: true,
    // },
    {
        type: "collapse",
        name: "產品管理",
        key: "productControl",
        icon: <FontAwesomeIcon icon={faHotel} />,
        collapse: [
            {
                name: "房型設定",
                key: "mainProducts",
                route: "/systemMang/products/mainProducts",
                component: <MainProductsBasicSetting/>
            },
            {
                name: "房間設定",
                key: "roomBasicSetting",
                route: "/systemMang/product/roomBasicSetting",
                component: <RoomBasicSetting/>
            },
            {
                name: "價格設定",
                key: "pricesBasicSetting",
                route: "/systemMang/prices/pricesBasicSetting",
                component: <PricesBasicSetting/>
            },
            {
                name: "加價產品設定",
                key: "otherProductsBasicSetting",
                route: "/systemMang/otherProduct/otherProductsBasicSetting",
                component: <OtherProductsBasicSetting/>
            },
            {
                name: "付款方式設定",
                key: "paymentMethodSetting",
                route: "/systemMang/paymentMethod/paymentMethodSetting",
                component: <PaymentMethodSetting/>
            },
            // {
            //     name: "保留設定",
            //     key: "reserveBasicSetting",
            //     route: "/systemMang/reserve/reserveBasicSetting",
            //     component: <ReserveBasicSetting/>
            // },
            {
                name: "常見問題設定",
                key: "quesAndAnsSetting",
                route: "/systemMang/quesAndAns/quesAndAnsSetting",
                component: <QuesAndAnsSetting/>
            },

            // {
            //     name: "Illustration",
            //     key: "illustration",
            //     route: "/authentication/sign-in/illustration",
            //     component: <SignInIllustration />,
            // },
            // {
            //     name: "Login",
            //     key: "login",
            //     route: "/login",
            //     component: <Login />,
            // },
            // {
            //     name: "登出",
            //     key: "logout",
            //     route: "/login",
            //     component: <Login />,
            //     // component: <Login />,
            // },
        ],
    },
    {
        type: "collapse",
        name: "交班管理",
        key: "handOverControl",
        icon: <FontAwesomeIcon icon={faTableList} />,
        collapse: [
            {
                name: "櫃台交班",
                key: "counterHandOverSetting",
                route: "/systemMang/handOverControl/counterHandOverSetting",
                component: <CounterHandOverSetting/>
            },
            {
                name: "房務交班",
                key: "housekeepingHandOverSetting",
                route: "/systemMang/handOverControl/housekeepingHandOverSetting",
                component: <HousekeepingHandOverSetting/>
            },
            {
                name: "工務交班",
                key: "publicWorksHandOverSetting",
                route: "/systemMang/handOverControl/publicWorksHandOverSetting",
                component: <PublicWorksHandOverSetting/>
            },
            {
                name: "故障與維修統計表",
                key: "faultReportSetting",
                route: "/systemMang/faultReport/faultReportSetting",
                component: <FaultReportSetting/>
            },
            {
                name: "房務管理報表",
                key: "housekeepingReport",
                route: "/systemMang/handOverControl/housekeepingReport",
                component: <HousekeepingReport/>
            },
            // {
            //     name: "故障與維修統計表",
            //     key: "repairReport",
            //     route: "/systemMang/handOverControl/repairReport",
            //     component: <RepairReport/>
            // },
        ],
    },
    {
        type: "collapse",
        name: "門鎖管理",
        key: "accAndDevBasicSet",
        icon: <FontAwesomeIcon icon={faDesktop} />,
        collapse: [
            {
                name: "房門門鎖設定",
                key: "accessControlBasicSetting",
                route: "/systemMang/accessControl/accessControlBasicSetting",
                component: <AccessControlBasicSetting/>
            },
            {
                name: "大門門鎖設定",
                key: "entranceLockBasicSetting",
                route: "/systemMang/accessControl/entranceLockBasicSetting",
                component: <EntranceLockBasicSetting/>
            },
            {
                name: "門鎖密碼設定",
                key: "passwordBasicSetting",
                route: "/systemMang/accessControl/passwordBasicSetting",
                component: <PasswordBasicSetting/>
            },
            {
                name: "門鎖開門事件",
                key: "eventsBasicSetting",
                route: "/systemMang/accessControl/eventsBasicSetting",
                component: <EventsBasicSetting/>
            },
            // {
            //     name: "其他設定",
            //     key: "devCtlBasicSet",
            //     route: "/systemMang/deviceControl/deviceControlBasicSetting",
            //     component: <DeviceControlBasicSetting/>
            // },
        ],
    },
    {
        type: "collapse",
        name: "報表管理",
        key: "reportControl",
        icon: <FontAwesomeIcon icon={faScroll} />,
        collapse: [
            {
                type: "collapse",
                name: "經營報表",
                key: "manageReportGroup",
                collapse: [
                    {
                        name: "銷售報表",
                        key: "salesReport",
                        route: "/systemMang/reportControl/dailySalesReport",
                        component: <DailySalesReport/>
                    },
                    {
                        name: "銷售報表",
                        key: "salesReport",
                        route: "/systemMang/reportControl/salesReport",
                        component: <SalesReport/>
                    },
                    {
                        name: "訂單來源銷售額統計報表",
                        key: "resourceSalesReport",
                        route: "/systemMang/reportControl/resourceSalesReport",
                        component: <ResourceSalesReport/>
                    },
                    {
                        name: "業務員銷售額統計報表",
                        key: "sellerReport",
                        route: "/systemMang/reportControl/sellerReport",
                        component: <SellerReport/>
                    },
                    {
                        name: "各館別產品銷售額統計表",
                        key: "productsReport",
                        route: "/systemMang/reportControl/productsReport",
                        component: <ProductsReport/>
                    },
                    {
                        name: "銷售目標統計表",
                        key: "salesTargetReport",
                        route: "/systemMang/reportControl/salesTargetReport",
                        component: <SalesTargetReport/>
                    },
                ]
            },
            {
                type: "collapse",
                name: "財務報表",
                key: "financeReportGroup",
                collapse: [
                    {
                        name: "營收發票月報表",
                        key: "invoiceReport",
                        route: "/systemMang/reportControl/invoiceReport",
                        component: <InvoiceReport/>
                    },
                ]
            }
        ],
    },
    {
        type: "collapse",
        name: "快捷式訂房設定",
        key: "smartBookingControl",
        icon: <FontAwesomeIcon icon={faSwatchbook} />,
        collapse: [
            {
                name: "快捷式訂房基本資料維護",
                key: "smartBookingSetting",
                route: "/systemMang/smartBooking/smartBookingSetting",
                component: <SmartBookingSetting/>
            },
        ],
    },
    // {
    //     type: "collapse",
    //     name: "票券管理",
    //     key: "coupTickMang",
    //     icon: <FontAwesomeIcon icon={faTicket} />,
    //     collapse: [
    //         {
    //             name: "票券基本資料維護",
    //             key: "tickBasicSet",
    //             route: "/systemMang/ticket/ticketBasicSetting",
    //             component: <TicketBasicSetting/>
    //         },
    //         {
    //             name: "銷售通路基本資料維護",
    //             key: "salesChannBasicSet",
    //             route: "/systemMang/ticket/salesChannelBasicSetting",
    //             component: <SalesChannelBasicSetting/>
    //         },
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "使用者管理",
    //     key: "usersProfileControl",
    //     icon: <FontAwesomeIcon icon={faUsers} />,
    //     collapse: [
    //         {
    //             name: "使用者設定",
    //             key: "profile",
    //             route: "/systemMang/accounts/accountSetting",
    //             component: <UserProfileSetting/>
    //         },
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "公用參數管理",
    //     key: "GloballSetting",
    //     route: "/systemMang/global/globalVarSetting",
    //     icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    //     component: <GlobalVarSetting/>,
    //     noCollapse: true,
    // },
    {
        type: "collapse",
        name: "基本資料管理",
        key: "basicSetting",
        icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        collapse: [
            {
                name: "公用參數管理",
                key: "globalVarSetting",
                route: "/systemMang/basicSetting/globalVarSetting",
                component: <GlobalVarSetting/>
            },
            {
                name: "使用者管理",
                key: "accountSetting",
                route: "/systemMang/basicSetting/accountSetting",
                component: <UserProfileSetting/>
            },
        ],
    },
    { type: "divider", key: "divider-1" },


];

export default routes;