import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDAlert from "../../../components/MDAlert";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
    Grid as DHXGrid,
    Toolbar as DHXToolbar,
    Layout as DHXLayout,
    Form as DHXForm,
    Window as DHXWindow,
    DataView as DHXDataview,
    message as DHXMessage,
} from "dhx-suite";
import {entranceLockGridColumn} from "./componentsConfig/entranceLockGridColumn";
import {entranceLockGridFormConfig} from "./componentsConfig/entranceLockGridFormConfig";
import {toolbar1Config,toolbar2Config} from "./componentsConfig/toolbarConfig";
import {WindowFootAccept,WindowFootClear,WindowFootClose} from "./componentsConfig/windowConfig";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import './style.css';
import Progress from '../../../assets/images/icons8-iphone-spinner.gif';
import axios from "axios";
import api_url from "../../../context/UrlContext";
import Image_error from "../../../assets/images/error.png";

const EntranceLockBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXEntranceLockTabLayout = useRef(null);

    useEffect(() => {

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>請耐心等候</span></div>",
            css: "dhxProgressWindow1"
        });

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:38},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let dhxEntranceLockTabLayout = new DHXLayout(DHXEntranceLockTabLayout.current,{
            type: "line",
            rows:[
                {
                    id: "entranceLockStatusListLayout",
                    height: "220px"
                },
                {
                    id: "entranceLockGridLayout",
                    css:"entranceLockGridLayout",
                },
            ]
        });

        let entranceLockStatusList = new DHXLayout(dhxEntranceLockTabLayout.getCell("entranceLockStatusListLayout"),{
            rows:[
                {id:"entranceLockStatusListToolbar", height: "content", gravity: false},
                {id: "entranceLockStatusList"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });

        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/accAndDev/loadEntranceLockStatusList", {
                        itemId1: hotel
                    },headers).then((response) =>{
                        if(response.status === 200){
                            console.log(response);
                            dhxDataview.data.removeAll();
                            dhxDataview.data.parse(response.data);
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;

                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        entranceLockStatusList.getCell("entranceLockStatusListToolbar").attach(toolbar1);

        axios.post(apiServer+"/accAndDev/loadEntranceLockStatusList", {
            itemId1: hotel
        },headers).then((response) =>{
            dhxDataview.data.removeAll();
            dhxDataview.data.parse(response.data);
        })
            .catch((error) => {
                console.log(error);
            });

        const template = ({room, device, status, battery,name }) => (`
            <div style="margin: 10px 0">
                <h2><i class="fa-solid fa-lock" style="padding:5px; margin:3px 3px 3px 0px;"></i>大門門鎖</h2>
                <div style="font-size: 1.2em"><span style="color: ${status == "ONLINE"?'green':'red'}"><i class="fa-solid fa-wifi" style="padding:5px; margin:3px 3px 3px 0px;"></i>&nbsp;狀態:${status}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style="color: ${battery <= 40?'red':'green'}"><i class="fa-solid fa-battery-full" style="padding:5px; margin:3px 3px 3px 0px;"></i>&nbsp;電量:${battery}%</span></div>
                <span>${name}</span>
            </div>
        `);

        let dhxDataview = new DHXDataview(entranceLockStatusList.getCell("entranceLockStatusList"), {
            selection: false,
            itemsInRow: 3,
            gap: 10,
            css: "dhx_widget--bordered",
            template: template,
        });

        let entranceLockGridLayout = new DHXLayout(dhxEntranceLockTabLayout.getCell("entranceLockGridLayout"),{
            rows:[
                {id:"entranceLockGridToolbar", height: "content", gravity: false},
                {id: "entranceLockGrid"},
            ]
        });

        let entranceLockGrid = new DHXGrid(null,{
            columns: entranceLockGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    entranceLockGrid_edit:function(event,data){
                        if(canEdit){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/accAndDev/loadEntranceLockFormData", {
                                itemId1: hotel
                            },headers).then((response) =>{
                                if (response.status === 200){
                                    if (response.data){
                                        entranceLockGridForm.setValue(response.data[0]);
                                        entranceLockGridDeleteForm.getItem("deleteFormName").setValue("大門門鎖");
                                        dhxEntranceLockGridWindow1.show();
                                        dhxProgressWindow1.hide();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    entranceLockGrid_delete:function(event,data){
                        if(canDelete){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/accAndDev/loadDoorLockSetFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if (response.status === 200){
                                    if (response.data){
                                        entranceLockGridDeleteForm.setValue(response.data[0]);
                                        entranceLockGridDeleteForm.getItem("deleteFormName").setValue("大門門鎖");
                                        entranceLockGridDeleteForm.getItem("AA112").setValue("");
                                        dhxEntranceLockGridWindow2.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        dhxProgressWindow1.show();

        axios.post(apiServer+"/accAndDev/loadEntranceLockGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        entranceLockGrid.data.removeAll();
                        entranceLockGrid.data.parse(response.data);
                        dhxProgressWindow1.hide();
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    dhxProgressWindow1.hide();
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        const toolbar2 = new DHXToolbar(null, {
        });

        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "setting":
                    if(canEdit){
                        dhxProgressWindow1.show();
                        entranceLockGridForm.clear();
                        entranceLockGridForm.setValue({"AA001": "0","AA002":hotel,"AA113": "2"});
                        entranceLockGridForm.clear("validation");

                        axios.post(apiServer+"/accAndDev/loadEntranceLockFormData", {
                            itemId1: hotel
                        },headers).then((response) =>{
                            if (response.status === 200){
                                if (response.data){
                                    if(response.data.length != 0){
                                        entranceLockGridForm.setValue(response.data[0]);
                                        dhxEntranceLockGridWindow1.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxEntranceLockGridWindow1.show();
                                        dhxProgressWindow1.hide();
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/accAndDev/loadEntranceLockGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                entranceLockGrid.data.removeAll();
                                entranceLockGrid.data.parse(response.data);
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        entranceLockGridLayout.getCell("entranceLockGridToolbar").attach(toolbar2);
        entranceLockGridLayout.getCell("entranceLockGrid").attach(entranceLockGrid);

        const dhxEntranceLockGridWindow1 = new DHXWindow({
            title: "設定大門門鎖視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 430,
            width:450,
            footer: true,
            css: "dhxEntranceLockGridWindow1"
        });

        dhxEntranceLockGridWindow1.footer.data.add({type:"spacer"});
        dhxEntranceLockGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxEntranceLockGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxEntranceLockGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxEntranceLockGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    entranceLockGridForm.clear();
                    entranceLockGridForm.setValue({"AA001": "0","AA002":hotel,"AA113":"2"});
                    entranceLockGridForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(entranceLockGridForm.getItem("AA109").getValue() === ""){
                        DHXMessage({
                            text: "請選擇門鎖類別",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(entranceLockGridForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填入門鎖ID",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/accAndDev/checkDoorLockId",
                        entranceLockGridForm.getValue(),headers
                    ).then((response1) =>{
                        if(response1.status === 200){
                            if(response1.data[0].result !== 0){
                                DHXMessage({
                                    text: "門鎖編號與其他門鎖重複",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                dhxProgressWindow1.hide();
                            }else{
                                axios.post(apiServer+"/accAndDev/updateEntranceLockFormData",
                                    entranceLockGridForm.getValue(),headers
                                ).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data[0].result == 1){
                                            DHXMessage({
                                                text: response2.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            entranceLockGridForm.clear();
                                            entranceLockGridForm.setValue({"AA001": "0","AA002":hotel,"AA113": "2"});
                                            entranceLockGridForm.clear("validation");

                                            axios.post(apiServer+"/accAndDev/loadEntranceLockGrid",{itemId1:hotel},headers)
                                                .then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            dhxProgressWindow1.hide();
                                                            entranceLockGrid.data.removeAll();
                                                            entranceLockGrid.data.parse(response3.data);
                                                            dhxEntranceLockGridWindow1.hide();
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                            axios.post(apiServer+"/accAndDev/loadEntranceLockStatusList", {
                                                itemId1: hotel
                                            },headers).then((response4) =>{
                                                if(response4.status === 200){
                                                    if(response4.data){
                                                        dhxDataview.data.removeAll();
                                                        dhxDataview.data.parse(response4.data);
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: response2.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxEntranceLockGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const entranceLockGridForm = new DHXForm(null,{
            rows: entranceLockGridFormConfig
        });

        dhxEntranceLockGridWindow1.attach(entranceLockGridForm);

        const dhxEntranceLockGridWindow2 = new DHXWindow({
            title: "刪除大門門鎖視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 610,
            footer: true,
            css: "dhxEntranceLockGridWindow2",
        });

        dhxEntranceLockGridWindow2.footer.data.add({type:"spacer"});
        dhxEntranceLockGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxEntranceLockGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxEntranceLockGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(entranceLockGridDeleteForm.getItem("AA112").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/accAndDev/deleteEntranceLock",
                        entranceLockGridDeleteForm.getValue(),headers
                    ).then((response1) =>{
                        if(response1.status === 200){
                            if(response1.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/accAndDev/loadEntranceLockGrid",{itemId1:hotel},headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                entranceLockGrid.data.removeAll();
                                                entranceLockGrid.data.parse(response2.data);
                                                dhxEntranceLockGridWindow2.hide();
                                                entranceLockGridDeleteForm.clear();
                                                entranceLockGridDeleteForm.clear("validation");
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                axios.post(apiServer+"/accAndDev/loadEntranceLockStatusList", {
                                    itemId1: hotel
                                },headers).then((response3) =>{
                                    if(response3.status === 200){
                                        if(response3.data){
                                            dhxDataview.data.removeAll();
                                            dhxDataview.data.parse(response3.data);
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxEntranceLockGridWindow2.hide();
                    entranceLockGridDeleteForm.clear();
                    entranceLockGridDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let entranceLockGridDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"entranceLockGridDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "entranceLockGridDeleteForm"},
            ]
        });

        const entranceLockGridDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxEntranceLockGridWindow2.attach(entranceLockGridDeleteFormLayout);
        entranceLockGridDeleteFormLayout.getCell("entranceLockGridDeleteForm").attach(entranceLockGridDeleteForm);

        return () =>{
            dhxEntranceLockTabLayout && dhxEntranceLockTabLayout.destructor();
        }

    }, [canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader title={"大門門鎖設定"}/>
                            <MDBox py={1} px={1}>
                                <div ref={DHXEntranceLockTabLayout} style={{height:"800px"}}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default EntranceLockBasicSetting;