export const entranceLockGridFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA113", hidden: true, value: "2"},
    {
        type: "radioGroup",
        label: "*門鎖類別：",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "芝麻雲",
                    value: 1,
                },
                {
                    type: "radioButton",
                    text: "通通鎖",
                    value: 2
                },
            ]
        },
        name: "AA109",
    },
    {
        type: "input",
        label: "*門鎖ID：",
        name: "AA101"
    },{
        type: "input",
        label: "密碼：",
        placeholder: "自動產生6位數字密碼",
        readOnly: true,
        name: "password"
    },
    {
        type: "input",
        label: "使用者：",
        placeholder: "大門共用密碼",
        readOnly: true,
        name: "AA110"
    },
    // {
    //     type: "textarea",
    //     label: "備註：",
    //     height: 100,
    //     name: "AA110"
    // },
];