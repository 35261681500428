export const toolbar1Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
];

export const toolbar2Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {id: "setting", type: "button", circle: true, value: "設定大門門鎖", size: "small", icon: "fa-solid fa-gear"},
    {
        id: "priceText",
        type: "customHTML",
        html: "<span style='font-weight: 800; font-size: 1rem; margin-left: 12px;color: red'>大門共用密碼將在新增後開始算起(包含當天)第8天的13:00自動更新</span>",
    },
];